/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { graphql } from "gatsby";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import MailchimpForm from "../components/MailchimpForm";

const EmailSection = () => (
  <>
    <h2 className="animation-appear animation-delay-1 grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 pb-10 f1">
      To be notified when the auction goes live, sign up below.
    </h2>
    <MailchimpForm />
  </>
);

const ContactSection = ({ href, value, title, delay }) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  return (
    <>
      <h3
        className={`animation-appear animation-delay-${delay} grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 mt-12 sm:mt-6 mb-3 border-b-white pb-2 b2 font-bold uppercase z-10`}
      >
        <span
          className={`transition-opacity ${
            hoverIndex !== null ? `opacity-50` : ``
          }`}
        >
          {title}
        </span>
      </h3>

      <h2
        className={`animation-appear animation-delay-${delay} grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 f1 z-10`}
      >
        <a
          href={href}
          className={`transition-opacity inline-block ${
            hoverIndex !== null && hoverIndex !== 1 ? `opacity-50` : ``
          }`}
          onMouseEnter={() => setHoverIndex(1)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          {value}
        </a>
      </h2>
    </>
  );
};

const AuctionPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="auction-page w-full relative sm:pt-32 sm:pb-64">
        <section className="w-full h-screen xs:h-auto relative flex items-center justify-center bg-slate text-off-white">
          <article className="grid">
            <EmailSection />
            <ContactSection
              href={`mailto:${frontmatter.contactEmail}`}
              title="Email"
              value={frontmatter.contactEmail}
              delay={3}
            />

            <ContactSection
              href={frontmatter.contactInstagramLink}
              title="Instagram"
              value={frontmatter.contactInstagramLabel}
              delay={5}
            />
          </article>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

AuctionPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({})
    })
  }).isRequired,
  location: PropTypes.shape({}).isRequired
};

export default AuctionPage;

export const query = graphql`
  query AuctionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        contactEmail
        contactInstagramLabel
        contactInstagramLink
        seoDescription
        seoKeywords
      }
    }
  }
`;
