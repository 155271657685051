import React, { useState } from "react";
import { fancyError, fancyLog, validateEmail } from "~utils/helpers";

const MailchimpForm = () => {
  const [form, setForm] = useState({
    email: null,
    submitted: false,
    submitting: false
  });

  //

  const onSubmit = e => {
    e.preventDefault();

    if (form.submitting || form.submitted || !validateEmail(form.email)) {
      return false;
    }

    const mailchimpData = {
      email: form.email,
      list_id: `c99d4a0a10`
    };

    setForm({
      ...form,
      submitting: true
    });

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/mailchimp`, {
      body: JSON.stringify(mailchimpData),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Mailchimp Complete`);

        setForm({
          ...form,
          submitted: true,
          submitting: false
        });
      })
      .catch(error => {
        fancyError(error);
      });

    return false;
  };

  let submitText = `Submit`;

  if (form.submitted) {
    return (
      <h2 className="grid-end-3 md:grid-end-4 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 h-10 relative text-off-white b1 font-bold bg-slate">
        Thanks for signing up. We will be in touch soon.
      </h2>
    );
  }

  if (form.submitting) {
    submitText = `Submitting`;
  }

  return (
    <form
      className={`email animation-appear animation-delay-1 form ${
        form.submitted || form.submitting
          ? `opacity-50 pointer-events-none`
          : ``
      } grid-end-3 md:grid-end-4 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 h-10 relative text-off-white b1 font-bold bg-slate`}
      onSubmit={onSubmit}
      // noValidate
    >
      <input
        className="email-input w-full h-full relative block px-2 bg-white text-slate"
        placeholder="Enter Email"
        onChange={e =>
          setForm({
            ...form,
            email: e.target.value
          })
        }
        type="email"
      />

      <input
        className="w-24 h-full absolute top-0 right-0 bottom-0 z-10 block cursor-pointer bg-slate text-off-white border-white uppercase b2"
        type="submit"
        value={submitText}
      />
    </form>
  );
};

export default MailchimpForm;
